import React from "react";
import Sider from "../navigation/Sider";
import Header from "../navigation/Header";

import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks";

const { Content } = Layout;

const App: React.FC = () => {

  const {user} = useAppSelector(state => state.userReducer);


  return (
    <>
      <Sider authed={false} />
      <Layout style={{marginLeft: 200}} className="containerWraperMinHeight" >
        <Header authed={true} />
        <Content style={{ overflow: "initial", padding: "0 12px" }}>
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};

export default App;
