import React, { Component, useEffect, useState } from "react";

import {
  DatePicker,
  Alert,
  message,
  Dropdown,
  Menu,
  Button,
  Typography,
} from "antd";
import moment, { Moment } from "moment";

import { Table } from "antd";
import { ITableColumn } from "../../models";
import { IHistoryItem } from "../../models/all";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useParams } from "react-router-dom";
import {
  exportXHR,
  getHistorianXHR,
} from "../../store/reducers/all/actionCreators";

const DateForat = "YYYY-MM-DD";

const { RangePicker } = DatePicker;

const History: React.FC = () => {
  const { historyItems, freezers, getHistorianStatus, exportStatus } =
  useAppSelector((state) => state.allReducer);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  
  const [freezer, set_freezer] = useState(
    freezers.find((x) => x.groups.map((x) => x.id).includes(id ? +id : 0))
  );

  const isVineFreezer = freezer?.id === 5

  // Methods
  const validate = (value: string | number | null) => value === 'non_existent' ? "-" : value
  const title = freezer?.groups && freezer.groups.length > 1 ? 
    freezer.groups.find(item => item.id === Number(id))?.name : 
    freezer?.name
 

  let columns: ITableColumn<IHistoryItem>[] = [
    {
      title: "Vrijeme",
      dataIndex: "created_at",
      render: (text, value) => moment(value.created_at.$date).format("DD.MM.YYYY HH:mm")
    },
    {
      title:  "Vrijednost temperature",
      dataIndex: "RoomTemp",
      render: (item: string, value) =>  <span className={item}>{item}&#x2103;</span>,
    },
    {
      title:  "Vrijednost vlage",
      dataIndex: "RoomTemp",
      hidden: !isVineFreezer,
      render: (item: string, value) =>  <span className={item}>{value.humidity ? parseFloat(value.humidity).toFixed(0) + "%" : ""}</span>,
    },
    {
      title: "Zadana temperatura",
      dataIndex: "SetTemp",
      render: (item) => <span className={item}>{item || "/"}&#x2103;</span>,
    },
    {
      title: "Bimetal",
      dataIndex: "pressure_switch_a",
      render: (item) => <span className={item}>{validate(item)}</span>,
    },
    {
      title: "Presostat",
      dataIndex: "pressure_switch_b",
      render: (item) => <span className={item}>{validate(item)}</span>,
    },
    {
      title: "Uljni presostat",
      dataIndex: "pressure_switch_c",
      render: (item) => <span className={item}>{validate(item)}</span>,
    },
    {
      title: "Isključen",
      dataIndex: "Standby/Running",
      render: (text, value) => value["Standby/Running"] === "0.0" ? "DA" : "NE"
    },
  ];

  


  const [dateParams, set_dateParams] = useState<{
    start: Moment | null;
    end: Moment | null;
  }>({
    start: moment().subtract(3, "d"),
    end: moment().add(1, "d"),
  });

  const [step, set_step] = useState("3");

  useEffect(() => {
    getData({
      start: dateParams.start?.format(DateForat),
      end: dateParams.end?.format(DateForat),
      group_id: id,
      step,
    });
  }, []);

  useEffect(() => {
    getData({
      start: dateParams.start?.format("YYYY-MM-DD"),
      end: dateParams.end?.format("YYYY-MM-DD"),
      group_id: id,
      step,
    });
  }, [dateParams]);

  const getData = (
    queryParams: Record<string, string | number | null | undefined | boolean>
  ) => {
    getHistorianXHR(
      {
        queryParams: queryParams,
        errorCallback: () => message.error("Dogodila se greska kod dohvata."),
      },
      dispatch
    );
  };

  const menu = (
    <Menu
      onClick={(a) => {
        set_step(a.key);
        getData({
          start: dateParams.start?.format(DateForat),
          end: dateParams.end?.format(DateForat),
          group_id: id,
          step: a.key,
        });
      }}
      items={[
        {
          key: "1",
          label: "Korak 1",
        },
        {
          key: "3",
          label: "Korak 3",
        },
        {
          key: "8",
          label: "Korak 8",
        },
      ]}
    />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3}>{ title }</Typography.Title>
        <div
          style={{ padding: 12, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            loading={exportStatus === "loading"}
            onClick={() => {
              exportXHR(
                {
                  errorCallback: () => message.error("Dogodila se greska."),
                  body: {
                    start: dateParams.start?.format(DateForat) || "",
                    end: dateParams.end?.format(DateForat) || "",
                    group_id: id ? +id : 0,
                    step,
                  },
                  successCallback: (data) => {
                    window.open(data.file, "_blank");
                  },
                },
                dispatch
              );
            }}
          >
            Izvezi
          </Button>
          <div style={{ width: 12 }} />
          <Dropdown overlay={menu}>
            <Button>Korak {step}</Button>
          </Dropdown>
          <div style={{ width: 12 }} />
          <RangePicker
            value={[dateParams.start, dateParams.end]}
            onChange={(date) =>
              set_dateParams({
                start: date ? date[0] : null,
                end: date ? date[1] : null,
              })
            }
          />
        </div>
      </div>

      <Table
        loading={getHistorianStatus === "loading"}
        columns={columns.filter(item => !item.hidden)}
        dataSource={historyItems}
      />
    </>
  );
};

export default History;
