import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IAccount, IAccountList, IUserAccount } from "../../../models/user";

// Define a type for the slice state
interface AccountState {
  accounts: IAccountList;
  account: IUserAccount | null;
  accountStatus: string;
  accountsStatus: string;
  addUserStatus: string;
  getAccountStatus: string;
  updateAccountStatus: string;
}

// Define the initial state using that type
const initialState = {
  accountAsset: [],
  accounts: [],
  account: null,
  accountStatus: "",
  accountsStatus: "loading",
  addUserStatus: "",
  getAccountStatus: "loading",
  updateAccountStatus: "",
  getAccountAssetStatus: "",
  joinAssetToAccountStatus: "",
  removeAssetFromAccountStatus: ""
} as AccountState;

export const accountsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    accounts(state) {
      state.accountsStatus = "loading";
    },
    accountsSucceess(state, action: PayloadAction<IAccountList>) {
      state.accounts =  action.payload;
      state.accountsStatus = "";
    },
    accountsFail(state, action: PayloadAction<string>) {
      state.accountsStatus = action.payload;
    },

    // -----
    addUser(state) {
      state.addUserStatus = "loading";
    },
    addUserSucceess(state, action: PayloadAction<IAccount>) {
      const arr = [...state.accounts];
      arr.push(action.payload);

      state.accounts = arr;
      state.addUserStatus = "";
    },
    addUserFail(state, action: PayloadAction<string>) {
      state.addUserStatus = action.payload;
    },

    // -----

    getAccount(state) {
      state.getAccountStatus = "loading";
    },
    getAccountSuccess(
      state,
      action: PayloadAction<IUserAccount>
    ) {
      state.account = action.payload;
      state.getAccountStatus = "";
    },
    getAccountFail(state, action: PayloadAction<string>) {
      state.getAccountStatus = action.payload;
    },

    // -----

    updateAccount(state) {
      state.updateAccountStatus = "loading";
    },
    updateAccountSuccess(
      state,
      action: PayloadAction<IUserAccount>
    ) {
      let a = [...state.accounts];
      let index = a.findIndex(x => x.id === action.payload.id);
      a[index] = action.payload;
      state.accounts = a;
      state.updateAccountStatus = "";
    },
    updateAccountFail(state, action: PayloadAction<string>) {
      state.updateAccountStatus = action.payload;
    },
  },
});

// Thunk function

export default accountsSlice.reducer;
