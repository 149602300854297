import React from "react";
import { useAppDispatch } from "../../hooks";
import { logoutXHR } from "../../store/reducers/user/actionCreators";

const Logout = () => {
  const dispatch = useAppDispatch();

  logoutXHR(null, dispatch);

  return <></>
  // return <Navigate to="/login" />;
};

export default Logout;
