import React, {  useEffect, useState } from "react";
import {Card } from "antd";
import { getTempFromRegisterValue } from "../../../helpers/functions";

import Device from "./Device";
import { IFreezer, INotificationLog, IStatuses } from "../../../models/all";

const convert = (value: number) : number => {
  if (value < 0) {
    return Math.abs(value) * 10 + 65036;
  }

  return value * 10;
};

interface IProps{
    freezer: IFreezer,
    freezer_id: number,
    notifications: INotificationLog[]
}

const Freezer:React.FC<IProps> = ({freezer, freezer_id, notifications}) =>  {

    let [sliderInterval, set_sliderInterval] = useState<NodeJS.Timer | null>(null);
    let [counter, set_counter] = useState<number>(0)

    const [elements, set_elements] =useState<{current_value_2: HTMLElement | null, current_value_1: HTMLElement | null, group_name: HTMLElement | null}>({
        current_value_1: document.getElementById("current_value_1"),
        current_value_2: document.getElementById("current_value_2"),
        group_name: document.getElementById("group_name")
    })
  
    const swapCurrentValueVisible = () => {
      
        if (freezer.groups.length > 1) {
          if (counter % 2 ) {
            if(elements.current_value_1 && elements.current_value_2 && elements.group_name){
                elements.current_value_1.style.display = "none";
                elements.group_name.innerHTML = " A"
                elements.current_value_2.style.display = "block";
            }
           
          } else {
            if(elements.current_value_1 && elements.current_value_2 && elements.group_name){
            elements.current_value_2.style.display = "block";
            elements.group_name.innerHTML = " B"
            elements.current_value_1.style.display = "none";
            }
          }
          counter++;
        }
      }

  useEffect(() => {

    swapCurrentValueVisible();
    sliderInterval = setInterval(() => {
      swapCurrentValueVisible();
    }, 3000);

    let a = sliderInterval;
    return () => {
 
        if (freezer.groups.length > 1) {
            console.log("unmount");
            clearInterval(a);
          }
    }
  }, [])



 
  

    


    const hasError: boolean = (notifications.length !== 0 && freezer_id === freezer.id)

    return (
      <>
        <div style={{ minWidth: "310px", margin: "6px", marginRight: 20, marginBottom: "33px" }}>
          <Card
            className={"cardContainer"}
            title={
              <>
                <div className={"freezerName"}>{ freezer.name }</div>
                <div>{freezer.subtitle}</div>
              </>
            }
            extra={
              <div
                className={"activeTag"}
                style={{
                  color: hasError ? hasError ? "#ff1845" : "#00d60e" : "#00d60e",
                  backgroundColor: hasError ? hasError ? "#ff184520" : "#00d60e10" : "#00d60e10",
                  border: hasError ? hasError ? "1px solid #ff184540" : "1px solid #00d60e40" : "1px solid #00d60e40"
                }}>
                  { hasError ? hasError ? "Greška" : "Aktivna" : "Aktivna" }
              </div>
            }
            bordered={false}
          >
            <div className={"currentTemperature"}>
              Trenutna temperatura {" "}
              <span style={{ fontWeight: 600 }} id="group_name"></span>
              {freezer.groups.map((item) => (
                <span
                  className={"current_value"}
                  id={"current_value_" + item.id}
                  style={{ float: "right" }}
                >
                  {
                    item.values?.find((x) => x.register.register === "4096")?.value}
                  &#x2103;
                </span>
              ))}
            </div>

            {freezer.groups.map((item) => (
              <Device
                type={freezer.type}
                key={item.id}
                devices={item.devices}
                id={item.id}
              />
            ))}
          </Card>
        </div>
      </>
    );
  }


export default Freezer;
