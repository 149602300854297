import React, { useEffect } from "react";

import { Button, Form, Input, message } from "antd";

import { IUserAccount } from "../models/user";
import {
  addUserXHR,
  updateAccountXHR,
} from "../store/reducers/accounts/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";


export interface IAccountForm {
  first_name: string,
  last_name: string,
  email: string
  id: number
}


interface IProps {
  account?: IAccountForm;
  close: () => void;
}

const AccountForm: React.FC<IProps> = ({ account, close }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addUserStatus } = useAppSelector((state) => state.accountsReducer);


  const onFinish = (values: IAccountForm) => {
 
    let _values: IAccountForm = {...values}
    if (account && account?.id !== 0) {
      // update user

      updateAccountXHR(
        {
          id: account.id,
          body: _values,
          errorCallback: (data: any) =>
            message.error("Greska na spremanje"),
          successCallback: (data: IUserAccount) => close(),
        },
        dispatch
      );

      return;
    }

    addUserXHR(
      {
        body: {..._values},
        errorCallback: () => message.error("Greska na spremanje"),
        successCallback: () => close(),
      },
      dispatch
    );
  };


  return (
    <Form form={form} initialValues={account} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"first_name"}
        label={"Ime"}
        rules={[{ required: true, message:  "Polje obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"last_name"}
        label={"Prezime"}
        rules={[{ required: true, message:  "Polje obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"email"}
        label={"Email"}
        rules={[{ required: true, message:  "Polje obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          loading={addUserStatus === "loading"}
          htmlType="submit"
          type="primary"
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AccountForm;
