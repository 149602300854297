import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={"Not found"}
      extra={
        <Button
          onClick={() => {
            navigate("/login");
          }}
          type="primary"
        >
          Natrag
        </Button>
      }
    />
  );
};

export default NotFound;
