 
 
import { BrowserRouter as Router } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./hooks";




import RoutesGenerator from "./navigation/RoutesGenerator";
import Loading from "./components/LoadingScreen";

const AppWraper = () => {

  const {getFreezersStatus} = useAppSelector(state => state.allReducer);

  if(getFreezersStatus === "loading"){
    return <Loading size="large" />
  }

  return (
    <Router>
      <RoutesGenerator />
    </Router>
  );
};

export default AppWraper;
