import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Divider, message, Tooltip } from "antd";
import { EditOutlined, HistoryOutlined, WarningOutlined } from "@ant-design/icons";

import { getTempFromRegisterValue } from "../../../helpers/functions";
import { IDevice } from "../../../models/all";

export type FAN_MODE = "cdF" | "odF" | "cdn" | "odn" | "cYF";


interface IProps {
  devices: IDevice[],
  id: number,
  type: null | "ambient"
}

interface ICommand {
  visibleModal: boolean,
  register: string,
  command_title: string,
  current_value: string | number,
  device_id: number,
}

const Device: React.FC<IProps> = ({ devices, id, type }) => {


  const [visible, set_visible] = useState<boolean>(false);

  const [toggleSetCommand, set_toggleSetCommand] = useState<ICommand>();


  const operational = devices.find((item) => item.category.id === 1);
  const preusre_switch = devices.find(
    (item) => item.category.id === 2
  );


  const process = Number(
    operational?.values.find((item) => item.register.register === "4336")?.register_value
  );




  const humidity: string | undefined = operational?.values.find(
    (item) => item.register.register === "4097"
  )?.value




  return (
    <>
      <div className={"freezerWrap" + " freezerWrapL"}>
        <div>
          {operational?.values.find(
            (item) => item.register.register === "4340"
          )?.register_value === "0" ? (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "900",
                color: "#a4a4ea",
                borderBottom: "1px solid #FFFFFF",
              }}
            >
              <span style={{ color: "rgb(234, 211, 176)" }}>
                Uređaj u standby modu!
              </span>{" "}
            </div>
          ) : null}
          {
            type === "ambient" ? <Divider style={{ margin: "6px 0" }} /> :
              <div className={"setTemperature"}>
                Zadana temperatura{" "}
                <span style={{ float: "right" }}>
                  <span>
                    {type === "ambient" ? "/" :
                      getTempFromRegisterValue(
                        operational?.values.find(
                          (item) => item.register.register === "4101"
                        )?.register_value
                      )}
                    &#x2103;
                  </span>
                </span>
              </div>
          }
          {humidity && <div className={"expandSectionLabel"}>
            <span style={{ fontSize: 15, marginTop: 12 }}>Vlaga</span>
            <span className={"expandSectionValue"} style={{ fontSize: 15, marginTop: 12 }} >
              {humidity}
              %
            </span>
          </div>}
        </div>
        {visible ? (
          <div>
            {" "}
            {type !== "ambient" && (<>
              <div style={{ height: "18px" }}></div>
              <div>
                <div className={"expandSectionTitle"}>Odmrzavanje </div>
                <div className={"expandSectionLabel"}>
                  Ponavljanje{" "}
                  <span className={"expandSectionValue"}>
                    {
                      operational?.values.find(
                        (item) => item.register.register === "4131"
                      )?.register_value
                    }{" "}
                    min
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Trajanje{" "}
                  <span className={"expandSectionValue"}>
                    {" "}
                    {
                      operational?.values.find(
                        (item) => item.register.register === "4132"
                      )?.register_value
                    }{" "}
                    min
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Temperatura završetka{" "}
                  <span className={"expandSectionValue"}>
                    {" "}
                    {getTempFromRegisterValue(
                      operational?.values.find(
                        (item) => item.register.register === "4130"
                      )?.register_value
                    )}
                    &#x2103;
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Trenutna temp. isparivača{" "}
                  <span className={"expandSectionValue"}>
                    {" "}
                    {getTempFromRegisterValue(
                      operational?.values.find(
                        (item) => item.register.register === "4099"
                      )?.register_value
                    )}
                    &#x2103;
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Način rada ventilatora{" "}
                  <span className={"expandSectionValue"}> cdF</span>
                </div>
                <div className={"expandSectionLabel"}>
                  Stanje kompresora{" "}
                  <span className={"expandSectionValue"}>
                    {" "}
                    {process & 1 ? "Upaljen" : "Ugašen"}
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Stanje ventilatora{" "}
                  <span className={"expandSectionValue"}>
                    {" "}
                    {process & 4 ? "Upaljen" : "Ugašen"}
                  </span>
                </div>
              </div>
              <div style={{ height: "18px" }}></div></>)}

            {id !== 5 && type !== "ambient" && (
              <div>
                <div className={"expandSectionTitle"}>Presostati </div>
                {preusre_switch?.statuses.pressure_switch_a !==
                  "non_existent" && (
                    <div className={"expandSectionLabel"}>
                      Bimetal 1{" "}
                      {preusre_switch?.statuses.pressure_switch_a === "OK" ? (
                        <span className={"expandSectionStatusOK"}>OK</span>
                      ) : (
                        <span className={"expandSectionStatusNotOK"}>
                          GREŠKA
                        </span>
                      )}
                    </div>
                  )}
                {preusre_switch?.statuses.pressure_switch_b !==
                  "non_existent" && (
                    <div className={"expandSectionLabel"}>
                      {preusre_switch?.id === 19 ? "Bimetal 2" : "Presostat"}{" "}
                      {preusre_switch?.statuses.pressure_switch_b === "OK" ? (
                        <span className={"expandSectionStatusOK"}>OK</span>
                      ) : (
                        <span className={"expandSectionStatusNotOK"}>
                          GREŠKA
                        </span>
                      )}
                    </div>
                  )}
                {preusre_switch?.statuses.pressure_switch_c !==
                  "non_existent" && (
                    <div className={"expandSectionLabel"}>
                      {id === 19 ? "Presostat" : "Uljni presostat"}{" "}
                      {preusre_switch?.statuses.pressure_switch_c === "OK" ? (
                        <span className={"expandSectionStatusOK"}>OK</span>
                      ) : (
                        <span className={"expandSectionStatusNotOK"}>
                          GREŠKA
                        </span>
                      )}
                    </div>
                  )}
              </div>
            )}
            {type !== "ambient" && <div style={{ height: "18px", marginTop: id === 5 ? -20 : 0 }}></div>}
            {type !== "ambient" && <>
              <div>
                <div className={"expandSectionTitle"}>Senzori </div>
                <div className={"expandSectionLabel"}>
                  Senzor temp. komore{" "}
                  {preusre_switch?.statuses.freezer_probe === "OK" ? (
                    <span className={"expandSectionStatusOK"}>OK</span>
                  ) : (
                    <span className={"expandSectionStatusNotOK"}>GREŠKA</span>
                  )}
                </div>
                <div className={"expandSectionLabel"}>
                  Senzor temp. isparivača{" "}
                  {preusre_switch?.statuses.evaporator_probe === "OK" ? (
                    <span className={"expandSectionStatusOK"}>OK</span>
                  ) : (
                    <span className={"expandSectionStatusNotOK"}>GREŠKA</span>
                  )}
                </div>
                <div className={"expandSectionSubtitle"}>
                  Rad kompresora kod kvara senzora{" "}
                </div>
                <div className={"expandSectionLabel"}>
                  Ponavljanje{" "}
                  <span className={"expandSectionValue"}>
                    <span style={{ marginRight: "9px" }}>
                      {
                        operational?.values.find(
                          (item) => item.register.register === "4123"
                        )?.register_value
                      }{" "}
                      min
                    </span>
                  </span>
                </div>
                <div className={"expandSectionLabel"}>
                  Trajanje{" "}
                  <span className={"expandSectionValue"}>
                    <span style={{ marginRight: "9px" }}>
                      {
                        operational?.values.find(
                          (item) => item.register.register === "4122"
                        )?.register_value
                      }{" "}
                      min
                    </span>
                  </span>
                </div>
              </div>
            </>}
            <div>
              <div style={{ height: "18px" }}></div>
              <div className={"expandSectionTitle"}>Alarm </div>
              <div className={"expandSectionLabel"}>
                Donja razina pokretanja{" "}
                <span className={"expandSectionValue"}>
                  <span style={{ marginRight: "9px" }}>
                    {operational?.down_temp}
                    &#x2103;
                  </span>
                </span>
              </div>
              <div className={"expandSectionLabel"}>
                Gornja razina pokretanja{" "}
                <span className={"expandSectionValue"}>
                  <span style={{ marginRight: "9px" }}>
                    {operational?.up_temp}
                    &#x2103;
                  </span>
                </span>
              </div>

              <div style={{ height: 20 }} />
              {
                operational?.up_humidity && <> <div className={"expandSectionLabel"}>
                  Donja razina pokretanja{" "}
                  <span className={"expandSectionValue"}>
                    <span style={{ marginRight: "9px" }}>
                      {operational?.down_humidity}
                      %
                    </span>
                  </span>
                </div>
                  <div className={"expandSectionLabel"}>
                    Gornja razina pokretanja{" "}
                    <span className={"expandSectionValue"}>
                      <span style={{ marginRight: "9px" }}>
                        {operational?.up_humidity}
                        %
                      </span>
                    </span>
                  </div>
                </>
              }
            </div>
          </div>
        ) : null}
        <div style={{ height: "18px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: 'flex', gap: 12 }}>
            <div className={"historyLink"}>
              <Tooltip title="Povijest">
                <Link to={`../history/${id}`}> <HistoryOutlined style={{ fontSize: 21 }} /></Link>
              </Tooltip>

            </div>
            <div className={"historyLink"}>
              <Tooltip title="Alarmi">
                <Link to={`../alarms/${id}`}> <WarningOutlined style={{ fontSize: 21 }} /></Link>
              </Tooltip>
            </div>
          </div>
          <div>
            {" "}
            <Button
              className={"expandButton"}
              onClick={() => set_visible(!visible)}
            >
              {visible ? "Zatvori" : "Proširi"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Device;
