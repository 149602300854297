import { userSlice } from ".";
import { AppDispatch } from "../..";
import { IDataToApi } from "../../../models";
import { IResetPassword } from "../../../pages/auth/Login";
import { ISetNewPassword } from "../../../pages/auth/SetNewPassword";
import { IRegistration, IUser, IUserAccount, MqttClient, MqttCreds } from "../../../models/user";
import { connectToMqtt } from "../../../mqtt";
import api from "../../../services";
import { allSlice } from "../all";

export const loginXHR = async (data: any, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.login());

    const response = await api().post<IUser>(
      `/accounts/auth/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data));

    dispatch(userSlice.actions.loginSucceess(response.data));
  } catch (error: any) {
    //
    console.log(error);
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data, error);
    }

    dispatch(userSlice.actions.loginFail(error.message));
  }
};

export const registrationXHR = async (data: Partial<IDataToApi<IRegistration, IUser>>, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.registration());

    const response = await api().post<IUser>(
      `companies/registration/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(userSlice.actions.registrationSuccess(response.data));
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.registrationFail(error.message));
  }
};

export const resetPasswordEmailXHR = async (data: Partial<IDataToApi<IResetPassword, IResetPassword>>, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.resetPasswordEmail());

    const response = await api().post<IResetPassword>(
      `accounts/reset_password_email/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(userSlice.actions.resetPasswordEmailSuccess(response.data));
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.resetPasswordEmailFail(error.message));
  }
};

export const setNewPasswordXHR = async (data: Partial<IDataToApi<ISetNewPassword, ISetNewPassword>>, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.setNewPassword());

    const response = await api().post<ISetNewPassword>(
      `accounts/set_new_password/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(userSlice.actions.setNewPasswordSuccess(response.data));
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.setNewPasswordFail(error.message));
  }
};

export const changePasswordXHR = async (data: any, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.changePassword());
    const response = await api().put<string>(
      `accounts/accounts/change_password/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.changePasswordSuccess(response.data));
  } catch (error: any) {
    //
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.changePasswordFail(error.message));
  }
};

export const logoutXHR = async (data: null, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");

  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch(userSlice.actions.logout());
  try {
    const response = await api().post<string>(`accounts/logout/`, {}, {headers: { Authorization: "Token " + token }}) 
  } catch (error: any) {
 
    console.log(error);
  }

};

export const meXHR = async (
  data: Partial<IDataToApi<null, IUserAccount>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.me());
    const response = await api().get<IUserAccount>(`/accounts/getuser/`, {
      params: data.queryParams,
      headers: { Authorization: "Token " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    const str = localStorage.getItem("user");

    if(str){
      let tmp: IUser = JSON.parse(str);
      tmp.account = response.data;
      dispatch(userSlice.actions.meSuccess(tmp));

    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.meFail(error.message));
    dispatch(allSlice.actions.getFreezersFail(error))
  }
};



export const confirmAndSetPasswordXHR = async (data: Partial<IDataToApi<{reg_token: string, password: string}, IUser>>, dispatch: AppDispatch) => {


      try {
        dispatch(userSlice.actions.confirmAndSetPassword());
        const response = await api().post<IUser>(`/accounts/confirm_and_set_password/`,
          data.body,
          );
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data));
      
        dispatch(userSlice.actions.confirmAndSetPasswordSuccess(response.data));

        if (data.successCallback) {
          data.successCallback(response.data);
        }
      } catch (error: any) { //lkl
        if (data.errorCallback) {
          data.errorCallback(error);
        }
    
        dispatch(userSlice.actions.confirmAndSetPasswordFail(error.message));
      }
    };



export const setMqttClient = async (data: Partial<IDataToApi<IUserAccount, MqttClient>>, dispatch: AppDispatch) => {
  try {
      connectToMqtt((client: MqttClient) =>{ 
      dispatch(userSlice.actions.setMqttClientSuccess(client));});
  } catch (error: any) { //lkl
    console.log(error);
    if(error?.response?.status === 401){
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.setMqttClientFail(error.message));
  }
};