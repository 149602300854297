import { allSlice } from ".";
import { AppDispatch } from "../..";
import { IDataToApi } from "../../../models";
import { Alarm, IFreezer, IHistoryItem } from "../../../models/all";
import api from "../../../services";
import { logoutXHR } from "../user/actionCreators";

export const getFreezersXHR = async (data: Partial<IDataToApi<null, IFreezer[]>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");
  try {
    dispatch(allSlice.actions.getFreezers(data.loading || ""));
    const response = await api().get<IFreezer[]>(`freezers/freezers/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Token " + token }
      });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.getFreezersSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.getFreezersFail(error.message));
  }
};



export const setCommandXHR = async (data: Partial<IDataToApi<{ device_id: number, register: string, value: string }, string>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.setCommand());
    const response = await api().post<string>(`/devices/${data.id}/send_command/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.setCommandSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.setCommandFail(error.message));
  }
};




export const getHistorianXHR = async (data: Partial<IDataToApi<null, IHistoryItem[]>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.getHistorian());
    const response = await api().get<IHistoryItem[]>(`freezers/freezers/historian/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Token " + token }
      });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.getHistorianSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.getHistorianFail(error.message));
  }
};




export const getAlarmsXHR = async (data: Partial<IDataToApi<null, Alarm[]>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.getAlarms());
    const response = await api().get<Alarm[]>(`alarms/alarms/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Token " + token }
      });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.getAlarmsSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(allSlice.actions.getAlarmsFail(error.message));
  }
};



export const setAlarmDescriptionXHR = async (data: Partial<IDataToApi<{ description: string }, Alarm>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.setAlarmDescription(data.id ? +data.id : 0));
    const response = await api().patch<Alarm>(`alarms/alarms/${data.id}/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.setAlarmDescriptionSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.setAlarmDescriptionFail(error.message));
  }
};



export const exportXHR = async (data: Partial<IDataToApi<{ step: string, end: string, start: string, group_id: number }, { file: string }>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.export());
    const response = await api().post<{ file: string }>(`freezers/export/excel/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.exportSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.exportFail(error.message));
  }
};


export const alarmsExportXHR = async (data: Partial<IDataToApi<{end: string, start: string, group_id: number }, { file: string }>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


  try {
    dispatch(allSlice.actions.export());
    const response = await api().post<{ file: string }>(`alarms/export/excel/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.exportSuccess(response.data));
  } catch (error: any) { //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.exportFail(error.message));
  }
};

