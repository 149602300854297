import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Alarm, IFreezer, IHistoryItem } from "../../../models/all";

// Define a type for the slice state
interface IAllState {
    freezers: IFreezer[],
    getFreezersStatus: string,
    setCommandStatus: string,
    getHistorianStatus: string,
    historyItems: IHistoryItem[],
    getAlarmsStatus: string,
    alarms: Alarm[],
    setAlarmDescriptionStatus: string,
    exportStatus: string
}

// Define the initial state using that type
const initialState = {
    freezers: [],
    getFreezersStatus: "loading",
    setCommandStatus: "",
    getHistorianStatus: "",
    historyItems: [],
    getAlarmsStatus: "",
    alarms: [],
    setAlarmDescriptionStatus: "",
    exportStatus: ""
} as IAllState;

export const allSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
       // -----
    
       getFreezers(state, action: PayloadAction<string>) {
        state.getFreezersStatus = action.payload;
      },
      getFreezersSuccess(state, action: PayloadAction<IFreezer[]>) {

            state.freezers = action.payload;
          state.getFreezersStatus = "";
      },
      getFreezersFail(state, action: PayloadAction<string>) {
        state.getFreezersStatus = action.payload;
      },
    

    
        // -----
        
        setCommand(state) {
            state.setCommandStatus = "loading";
          },
          setCommandSuccess(state, action: PayloadAction<string>) {
    
    
              state.setCommandStatus = "";
          },
          setCommandFail(state, action: PayloadAction<string>) {
            state.setCommandStatus = action.payload;
          },


           // -----
            
           getHistorian(state) {
            state.getHistorianStatus = "loading";
          },
          getHistorianSuccess(state, action: PayloadAction<IHistoryItem[]>) {
    
              state.historyItems = action.payload;
              state.getHistorianStatus = "";
          },
          getHistorianFail(state, action: PayloadAction<string>) {
            state.getHistorianStatus = action.payload;
          },
        

  
      // -----
      
      getAlarms(state) {
          state.getAlarmsStatus = "loading";
        },
        getAlarmsSuccess(state, action: PayloadAction<Alarm[]>) {
            state.alarms = action.payload;
            state.getAlarmsStatus = "";
        },
        getAlarmsFail(state, action: PayloadAction<string>) {
          state.getAlarmsStatus = action.payload;
        },
      
        // -----
          
        setAlarmDescription(state, action: PayloadAction<number>) {
          state.setAlarmDescriptionStatus = "loading" + action.payload;
        },
        setAlarmDescriptionSuccess(state, action: PayloadAction<Alarm>) {
          let tmp = [...state.alarms];
          let index = tmp.findIndex(x => x.id === action.payload.id);
          console.log(typeof index, index)
          tmp[index] = action.payload;
          state.alarms = tmp;
          state.setAlarmDescriptionStatus = "";
        },
        setAlarmDescriptionFail(state, action: PayloadAction<string>) {
          state.setAlarmDescriptionStatus = action.payload;
        },


 
      // -----
      
      export(state) {
          state.exportStatus = "loading";
        },
        exportSuccess(state, action: PayloadAction<{file: string}>) {
  
  
            state.exportStatus = "";
        },
        exportFail(state, action: PayloadAction<string>) {
          state.exportStatus = action.payload;
        },
      
      
        
  },
});

// Thunk function

export default allSlice.reducer;
