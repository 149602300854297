import React, { useState } from "react";

import { Button, Layout, Modal } from "antd";

import { Link } from "react-router-dom";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import ChangePasword from "../componentsform/ChangePassword";

const { Header } = Layout;

interface IProps {
  authed: boolean;
}

const _Header: React.FC<IProps> = ({ authed }) => {

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false)

  // Methods
  function handleClose(){
    set_modalVisible(false)
  }

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div></div>
        <div style={{ padding: "0 39px" }}>
          <div className="spaceBetweenRow">
            {authed && <Link to={"logout"}>Odjava</Link>}
            {authed && (
              <Button 
                onClick={() => set_modalVisible(true)}
                type="text"
                style={{ marginLeft: 20, color: "#fff" }}
              >
                <KeyOutlined />
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Change password modal */}
      <Modal 
        title="Promijenite lozinku"
        visible={modalVisible} 
        onCancel={handleClose}
        footer={null}
        destroyOnClose
      >
        <ChangePasword close={handleClose} />
      </Modal>
    </Header>
  );
};

export default _Header;
