import AppLayout from "../layouts/App";
import AuthLayout from "../layouts/Auth";

import { Navigate, useLocation } from "react-router-dom";
import RegistrationWithToken from "../pages/auth/RegistrationWithToken";
import Login from "../pages/auth/Login";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/dashboard";
import HumanResources from "../pages/humanresources";
import Logout from "../pages/auth/Logout";
import NotFound from "../pages/NotFound";
import AccountSpec from "../pages/account/pages/Spec";


import {
  AlertOutlined,
  BarcodeOutlined,
  BulbOutlined,
  ControlOutlined,
  DashboardOutlined,
  FileProtectOutlined,
  SettingOutlined,
  SolutionOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Account from "../pages/account";

import History from "../pages/History";
import Alarms from "../pages/alarms";

export interface IRoute {
  path: string;
  element: JSX.Element;
  children?: Array<IRoute>;
  icon: any;
  additionalItem?: JSX.Element;
  permissionCode?: string;
  name?: string
}

export function AppRoutes(): IRoute {


  let tmpRoutes =  {
    path: "/app",
    element: <AppLayout />,
    icon: null,
    children: [
      {
        path: "",
        element: <Navigate replace to="dashboard" />,
        children: undefined,
        icon: null,
      },
      {
        path: "dashboard",
        element: <PrivateRoute childern={<Dashboard />} />,
        children: undefined,
        icon: <DashboardOutlined />,
        name: "Nadzorna ploča"
      },
      {
        path: "history/:id",
        element: <PrivateRoute childern={<History />} />,
        children: undefined,
        icon: null,
      },
      {
        path: "alarms/:id",
        element: <PrivateRoute childern={<Alarms />} />,
        children: undefined,
        icon: null,
      },
      {
        path: "logout",
        element: <PrivateRoute childern={<Logout />} />,
        children: undefined,
        icon: null
      },
      {
        path: "humanresources",
        element: <PrivateRoute childern={<HumanResources />} />,
        children: undefined,
        icon: <UsergroupAddOutlined />,
        name: "Ljudski resursi"
      },
    ],
  };
  return tmpRoutes;
};
export const AuthRoutes = (auth: boolean): IRoute => {
  return {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Navigate replace to={auth ? "/app" : "/login"} />,
        children: undefined,
        icon: null,
      },
      {
        path: "login",
        children: undefined,
        element: auth ? <Navigate to={"/app"} replace /> : <Login />,
        icon: null,
      },
      {
        path: "registrationwithtoken",
        children: undefined,
        element: <RegistrationWithToken />,
        icon: null,
      },
      {
        path: "*",
        children: undefined,
        element: <NotFound />,
        icon: null,
      },
    ],
    icon: null,
  };
};
