import { Button, Drawer, Input, message, Popconfirm, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { IAccount, IAccountList } from "../../models/user";
import Columns from "../../components/Columns";
import { Link, useLocation } from "react-router-dom";
import { sortTableColumns } from "../../helpers/functions";
import { accountsXHR } from "../../store/reducers/accounts/actionCreators";
import AccountForm, { IAccountForm } from "../../componentsform/AccountForm";
import moment from "moment";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import LoadMoreButton from "../../components/LoadMoreButton";
import api from "../../services";
import { allSlice } from "../../store/reducers/all";
import { userSlice } from "../../store/reducers/user";
import { accountsSlice } from "../../store/reducers/accounts";
import { logoutXHR } from "../../store/reducers/user/actionCreators";

const STORAGE_COLUMNS_NAME = "HumanResourcesTable";

const HumanResources: React.FC = () => {

  // Hooks
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Variables
  const [addAccountVisible, set_addAccountVisible] = useState<IAccountForm>();
  const [search, set_search] = useState<string>("");
  const { accountsStatus, accounts } = useAppSelector(
    (state) => state.accountsReducer
  );
  const [data, set_data] = useState<IAccount[]>([]);
  const [loading, set_loading] = useState<number>(0);


  // Methods
  const del = async (id: number) =>{
    set_loading(id);
      let token = await localStorage.getItem("token");

      try {
        await api().delete(`/accounts/accounts/${id}/` || "", {
          headers: { "Authorization": "Token " + token }
        })
        let tmp = [...accounts];
        let index = tmp.findIndex(x => x.id === id);
        tmp.splice(index, 1);
        dispatch(accountsSlice.actions.accountsSucceess(tmp));
      } catch (error: any) {
        if (error?.response?.status === 401) {
          logoutXHR(null, dispatch);
        }
      }
      set_loading(0);
  }


  const columns: ITableColumn<IAccount>[] = [
    {
      title: "Naziv",
      dataIndex: "name",
      visible: true,
      width: "320px",
      render: (text: string, value: IAccount) => (
        <span >{value.first_name + " " + value.last_name} </span>
      ),
    },
    { title: "Email", dataIndex: "email", visible: true },
    {
      visible: true,
      title: "",
      dataIndex: "",
      width: "200px",
      render: (text, value) => {
        return <div style={{display: "flex", gap: 20 }} >
          <Button loading={loading === value.id} onClick={() => {
            set_addAccountVisible({email: value.email, first_name: value.first_name, last_name: value.last_name, id: value.id})
          }} ><EditOutlined /></Button>
          <Popconfirm onConfirm={() => del(value.id)} title="Nastaviti sa akcijom" >
            <Button danger type="dashed" ><DeleteOutlined /></Button>
          </Popconfirm>
        </div>
      }
    }
  ];

  useEffect(() => {
    set_data(accounts);
  }, [accounts]);

  const getAccounts = () => {
    accountsXHR(
      { errorCallback: (data: any) => message.error("Greska na dohvat") },
      dispatch
    );
  };

  useEffect(() => {
    getAccounts();
  }, []);

  let filtredData = [];
  if (search) { filtredData = data?.filter((x) => (x.first_name + x.last_name).toLowerCase().includes(search));} 
  else { filtredData = data; }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <Typography.Title level={3}>Ljudski resursi</Typography.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Pretraga"}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              onSearch={(v) => set_search(v.toLowerCase())}
              style={{ marginRight: 18 }}
            />
          </div>
          <Button onClick={() => set_addAccountVisible({email: "", first_name: "", last_name: "", id: 0})} type="primary">
            Dodaj
          </Button>
        </div>
      </div>

      <Spin spinning={accountsStatus === "loading"}>
        <Table
          rowKey={(item) => item.id}
          dataSource={filtredData}
          columns={columns}
        />
      </Spin>

      <Drawer
        title={"Dodaj korisnika"}
        width={540}
        destroyOnClose={true}
        children={
          <AccountForm
            account={addAccountVisible}
            close={() => set_addAccountVisible(undefined)}
          />
        }
        visible={!!addAccountVisible}
        onClose={() => set_addAccountVisible(undefined)}
      />
    </div>
  );
};

export default HumanResources;
