import React from 'react';

import { Spin } from 'antd';

interface IProps{
    size: "small" | "default" | "large"
}

const Loading:React.FC<IProps> = ({size}) => {

        return (
            <div>
                <Spin size={size || "large"}/>
            </div>
        )
}

export default Loading;