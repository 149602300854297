import React, { useEffect, useState } from "react";

// AntDesign Components
import {  Alert, message, Spin } from "antd"; 
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getFreezersXHR } from "../../store/reducers/all/actionCreators";
import Freezer from "./components/Freezer";
import { statuses } from "../../helpers/functions";


const Dashboard:React.FC = () => {
 
  // Variables
  const dispatch = useAppDispatch();
  const {freezers, getFreezersStatus} = useAppSelector(state => state.allReducer)
  const [loading, set_loading] = useState(true);

  // Methods
  function getFreezers(){
    getFreezersXHR({
      successCallback: () => { if(loading) set_loading(false); },
      errorCallback: () =>{ message.error("Dogodila se greska na dohvatu."); set_loading(false)}
    }, dispatch)
  }

  useEffect(() => {
    getFreezers()
    const interval = setInterval(() => {
      getFreezers()
    }, 2700);
    return () => clearInterval(interval);
  }, []);
    
  
  return (
    <>
    
      <div id="dashboard">
        <div className={"headerContainer"}>
          <h1 className={"headerTitle"}>Pregled temperatura skladišta</h1>
          <h4 className={"headerDescription"}>Ukupno { freezers.length } jedinica</h4>
        </div>
        <Spin spinning={loading}>
        <div className={"freezerContainer" + " freezerContainer"}>
          {freezers.map((item) => (
            <Freezer
              key={item.id}
              freezer={item}
              freezer_id={item.id}
              notifications={statuses(freezers)}
            />
          ))}
        </div>
        </Spin>
      </div>
    </>
  );
}



export default Dashboard;
// export default cmp(Dashboard);
