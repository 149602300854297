import React from "react";

import { Menu, Layout } from "antd";

import { Link, useLocation } from "react-router-dom";
import { AppRoutes, IRoute } from "./Routes";
import { useAppSelector } from "../hooks";

const { Sider } = Layout;

const NO_SIDER_ROUTE: string[] = [
  "*",
  "logout",
  "profile",
  "humanresources/:id",
  "history/:id",
  "alarms/:id",
  "change-password"
];

interface IProps {
  authed: boolean;
}

const _Sider: React.FC<IProps> = ({ authed }) => {
  const location = useLocation();
  const tabKey = location.pathname.split("/")[2];

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div style={{ minHeight: "64px" }} className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        activeKey={tabKey}
        selectedKeys={[tabKey]}
        defaultSelectedKeys={[tabKey]}
      >
        {AppRoutes().children
          ?.filter((x): boolean => !NO_SIDER_ROUTE.includes(x.path))
          .map((item) => {
            return (
            <Menu.Item style={{position: "relative"}}  key={item.path} icon={item.icon}>
              <Link to={item.path}>{item.name || ""}</Link>
              {item.additionalItem}
            </Menu.Item>
          )})}
      </Menu>
    </Sider>
  );
};

export default _Sider;
