import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IResetPassword } from "../../../pages/auth/Login";
import { ISetNewPassword } from "../../../pages/auth/SetNewPassword";
import { IUser, IUserAccount, MqttClient } from "../../../models/user";

const initialUser: IUser = {
  token: "",
  account: {
    id: 0,
    email: "",
    first_name: "",
    last_name: "",
  },
};

// Define a type for the slice state
interface UserState {
  user: IUser;
  loginStatus: string;
  registrationStatus: string;
  changePasswordStatus: string;
  meStatus: string;
  confirmAndSetPasswordStatus: string;
  resetPasswordEmailStatus: string;
  setNewPasswordStatus: string
  setMqttClientStatus: string,
  mqttClient: MqttClient
}

// Define the initial state using that type
const initialState = {
  user: initialUser,
  loginStatus: "",
  registrationStatus: "",
  changePasswordStatus: "",
  meStatus: "loading",
  confirmAndSetPasswordStatus: "",
  resetPasswordEmailStatus: "",
  setNewPasswordStatus: "",
  setMqttClientStatus: "",
  mqttClient: null
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      state.user = initialUser;
    },
    login(state) {
      state.loginStatus = "loading";
    },
    loginSucceess(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.loginStatus = "";
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginStatus = "";
    },
    // -----

    registration(state) {
      state.registrationStatus = "loading";
    },
    registrationSuccess(state, action: PayloadAction<IUser>) {
      state.registrationStatus = "";
    },
    registrationFail(state, action: PayloadAction<string>) {
      state.registrationStatus = action.payload;
    },
    // -----

    changePassword(state) {
      state.changePasswordStatus = "loading";
    },
    changePasswordSuccess(state, action: PayloadAction<string>) {
      state.changePasswordStatus = "";
    },
    changePasswordFail(state, action: PayloadAction<string>) {
      state.changePasswordStatus = action.payload;
    },

    // -----

    me(state) {
      state.meStatus = "loading";
    },
    meSuccess(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.meStatus = "";
    },
    meFail(state, action: PayloadAction<string>) {
      state.meStatus = action.payload;
    },

    // -----

    confirmAndSetPassword(state) {
      state.confirmAndSetPasswordStatus = "loading";
    },
    confirmAndSetPasswordSuccess(
      state,
      action: PayloadAction<IUser>
    ) {
      state.user = action.payload;
      state.confirmAndSetPasswordStatus = "";
    },
    confirmAndSetPasswordFail(state, action: PayloadAction<string>) {
      state.confirmAndSetPasswordStatus = action.payload;
    },

    // -----

    resetPasswordEmail(state) {
      state.resetPasswordEmailStatus = "loading";
    },
    resetPasswordEmailSuccess(state, action: PayloadAction<IResetPassword>) {
      state.resetPasswordEmailStatus = "";
    },
    resetPasswordEmailFail(state, action: PayloadAction<string>) {
      state.resetPasswordEmailStatus = action.payload;
    },

    // -----

    setNewPassword(state) {
      state.setNewPasswordStatus = "loading";
    },
    setNewPasswordSuccess(state, action: PayloadAction<ISetNewPassword>) {
      state.setNewPasswordStatus = "";
    },
    setNewPasswordFail(state, action: PayloadAction<string>) {
      state.setNewPasswordStatus = action.payload;
     // -----
    },
    
     setMqttClient(state) {
      state.setMqttClientStatus = "loading";
    },
    setMqttClientSuccess(state, action: PayloadAction<MqttClient>) {
        state.mqttClient = action.payload;
        state.setMqttClientStatus = "";
    },
    setMqttClientFail(state, action: PayloadAction<string>) {
      state.setMqttClientStatus = action.payload;
    },
  },
});

// Thunk function

export default userSlice.reducer;
