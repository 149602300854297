import { Button, Form, Input, message, Row, Col, Typography, Divider } from "antd";
import React, { useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  confirmAndSetPasswordXHR,
  loginXHR,
} from "../../store/reducers/user/actionCreators";

const RegistrationWithToken: React.FC = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
 
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { loginStatus } = useAppSelector((state) => state.userReducer);

  const searchParams = new URLSearchParams(search);

  const onSave = (values: { password: string; confirm: string }): void => {
    confirmAndSetPasswordXHR(
      {
        body: {
          reg_token: searchParams.get("reg_token") || "",
          password: values.password,
        },
        errorCallback: () => "Greska na spremanje.",
        successCallback: () => navigate("/app", {replace: true})
      },
      dispatch
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: '100%',
        minWidth: '100vw',
        height: '100%',
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <Row style={{ width: '100%', height: '100%', minHeight: '100vh' }}>
        <Col xs={0} lg={0}>
          <div 
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: "#001529"
            }}
          />
        </Col>
        <Col xs={24} lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: '100%',
            height: '100%',
            minHeight: '100vh'
          }}  
        >
          <div 
            style={{ 
              maxWidth: 600, width: 600,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title 
              level={1}
              style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: 'center' }}
            >
              Postavite lozinku
            </Typography.Title>

            <Typography.Title 
              level={5}
              style={{ paddingBottom: 24, color: "#434343aa", fontWeight: 300, textAlign: 'center' }}
            >
              Postavite lozinku kako biste nastavili dalje
            </Typography.Title>
            <Form form={form} onFinish={onSave} layout="vertical">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Ovo polje je obavezno.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ovo polje je obaveeno.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Netocan unos")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Divider />

              <Form.Item>
                <Button
                  loading={loginStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {"Spremi"}
                </Button>
              </Form.Item>

              <div style={{ width: 480 }}/>
            </Form>
            </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegistrationWithToken;
