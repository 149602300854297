import { ITableColumn } from "../models";
import { IFreezer, INotificationLog } from "../models/all";

interface IStorageColumns {
    visible: string | boolean;
    dataIndex: string;
  }
  
export const sortTableColumns = async (
    columns: ITableColumn<any>[],
    memorizedName: string,
    setCallback: (columns: ITableColumn<any>[]) => void
  ): Promise<void> => {
    let sortBy = await localStorage.getItem(memorizedName);
  
    if (sortBy === null) {
      setCallback(columns);
      localStorage.removeItem(memorizedName);
      return;
    }
  
    let _columns: IStorageColumns[] = JSON.parse(sortBy);
  
    const sorted: ITableColumn<any>[] = [];
  
    try {
      _columns.forEach((x) => {
        const column = columns.find((y) => y.dataIndex === x.dataIndex);
        if (column) {
          column.visible = !!x.visible;
          sorted.push(column);
        }
      });
  
      setCallback(sorted);
    } catch (error) {
      setCallback(columns);
      localStorage.removeItem(memorizedName);
    }
  };
  

 
  

  const MAX_NEGATIVE = 65536;

export const getValueForRegister = (value: number) => {
  if (value < 0) {
    return (MAX_NEGATIVE + value * 10).toString();
  }

  return (value * 10).toString();
};

export const getTempFromRegisterValue = (_value: string | undefined):number | string => {
  if(!_value){
    return ""
  }
  let value = parseInt(_value);
  if (value >= 64000) {
    let tmp_res = (value - MAX_NEGATIVE) / 10;

    // if (tmp_res < -19.9) {
    //   tmp_res = Math.floor(tmp_res);
    // }

    return tmp_res.toFixed(1);
  }
  return parseFloat((value / 10).toString()).toFixed(1);
};


export function getStatus(freezer: IFreezer) {
  let counter = 0;
  const checkNumber = freezer.groups.length;

  freezer.groups.map((item) =>
    item.devices.map((x) => {
        if (IN(x.id) && x.statuses.pressure_switch_a !== "OK") {
          counter++;
        }
    })
  );

  if (counter === checkNumber) {
    return 1;
  }

  return 2;
}

const IN = (x: number) => {
  let arr = [2, 4, 11, 12, 13];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === x) {
      return true;
    }
  }
  return false;
};

// 9
// 8
// 7 statusne komore device ID
// 6
// 5

//1 GREŠKA
//2 AKTIVNA
//3 NEAKTIVNA


const statuses = (freezers: IFreezer[]): INotificationLog[] => {
  var notification_log: INotificationLog[] = [];
  const wanted_info = [
    "pressure_switch_a",
    "pressure_switch_b",
    "pressure_switch_c",
  ];

  const statusNames = (name: string) => {
    if (name === "pressure_switch_a") return "Bimetal";
    else if (name === "pressure_switch_b") return "Presostat";
    else if (name === "pressure_switch_c") return "Uljni presostat";
  };

  freezers.map((freezer) => {
    freezer.groups.map((group) => {

      const entries = Object.entries(
        group.devices.find((item) => item.category.name === "Statusna")?.statuses || {})

      for (const [key, value] of entries
      ) {
        if (wanted_info.includes(key)) {
          if (value !== 'OK' && value !== 'non_existent') {
            notification_log.push({
              freezer: freezer.name,
              freezer_id: freezer.id,
              group: group.name,
              status: {
                name: key,
                value: statusNames(key) || "",
              },
            });
          }
        }
      }
    });
  });

  //   console.log(notification_log, "STATUSES");

  return notification_log;
};

const values = (freezers: IFreezer[]) => {
  var notification_log: INotificationLog[] = [];

  let counter = 0;

  freezers.map((freezer) => {
    freezer.groups.map((group) => {
      const device = group.devices.find(
        (item) => item.category.name === "Upravljacka"
      );

      const current_temperature = +getTempFromRegisterValue(
        device?.values.find((item) => item.register.register === "4096")
          ?.register_value
      );

      const alarm_low_temperature = +getTempFromRegisterValue(
        device?.values.find((item) => item.register.register === "4150")
          ?.register_value
      );
      const alarm_high_temperature = +getTempFromRegisterValue(
        device?.values.find((item) => item.register.register === "4151")
          ?.register_value
      );

      const evaporation_probe_state =
        Number(
          device?.values.find((item) => item.register.register === "4337")
            ?.register_value
        ) & 2;

      const room_probe_state =
        Number(
          device?.values.find((item) => item.register.register === "4337")
            ?.register_value
        ) & 1;

        

      if (room_probe_state) {
        notification_log.push({
          freezer_id: freezer.id,
          freezer: freezer.name,
          group: group.name,
          status: {
            name: "room_probe_state",
            value: "Sonda temperature",
          },
        });
      }

      if (evaporation_probe_state) {
        notification_log.push({
          freezer_id: freezer.id,
          freezer: freezer.name,
          group: group.name,
          status: {
            name: "evaporation_probe_state",
            value: "Sonda isparivača",
          },
        });
      }

      if (current_temperature < alarm_low_temperature) {
        notification_log.push({
          freezer_id: freezer.id,
          freezer: freezer.name,
          group: group.name,
          status: {
            name: "alarm_low_temperature",
            value: "Temperatura preniska",
          },
        });
      }

      if (current_temperature > alarm_high_temperature) {
        notification_log.push({
          freezer_id: freezer.id,
          freezer: freezer.name,
          group: group.name,
          status: {
            name: "alarm_high_temperature",
            value: "Temperatura previsoka",
          },
        });
      }
    });
  });

  return notification_log;
};

export { statuses, values };

export function isRoomTempOK(freezers: IFreezer[]) {
  for (let i = 0; i < freezers.length; i++) {
    for (let j = 0; j < freezers[i].groups.length; j++) {
      const group = freezers[i].groups[j];

      const current_temperature = +getTempFromRegisterValue(
        group.values.find((x) => x.register.register === "4096")?.register_value
      );

      let operational = group.devices.find(
        (x) => x.category.name === "Upravljacka"
      );

      const alarm_low_temperature = +getTempFromRegisterValue(
        operational?.values.find((item) => item.register.register === "4150")
          ?.register_value
      );
      const alarm_high_temperature = +getTempFromRegisterValue(
        operational?.values.find((item) => item.register.register === "4151")
          ?.register_value
      );

      if (
        current_temperature >= alarm_high_temperature ||
        current_temperature <= alarm_low_temperature
      ) {
        return false;
      }
    }
  }

  return true;
}


export const updateFreezer = (freezers: IFreezer[], data: string[]) => {
  //data[0] = komora id
  //data[1] = grupa id
  //data[2] = device id
  //data[3] = register address
  //data[4] = value


  if (freezers.length === 0) {
    return freezers;
  }

  try {
    let indexOfObject = freezers.findIndex((item) => +data[0] === item.id);
    let tmp = { ...freezers[indexOfObject] };

    let group = tmp.groups.find((item) => item.id === +data[1]);
    if (data[3] === "4096") {
      let a = group?.values.find(
        (item) => item.register.register === data[3]
      );
      if(a){
        a.register_value = data[4]
      }
      return freezers;
    }

    let deviceIndex = group?.devices.findIndex((item) => item.id === +data[2]);
    let b;
    if(deviceIndex && deviceIndex !== -1){
      b = group?.devices[deviceIndex].values.find(
        (item) => item.register.register === data[3]
      );
    }
    if(b){
      b.register_value = data[4];
    }

  } catch (error) {
    // // alert(error);
    // console.log(error, "updateFreezer");
    // console.log(freezers, data);
  }

  // console.log(freezers);

  return freezers;
};

export const updateFreezerStatus = (freezers: IFreezer[], data: string[]) => {
  //data[0] = komora id
  //data[1] = grupa id
  //data[2] = device id
  //data[3] = register address
  //data[4] = value spliter :

  if (freezers.length === 0) {
    return freezers;
  }

  try {
    let indexOfObject = freezers.findIndex((item) => +data[0] === item.id);
    let tmp = { ...freezers[indexOfObject] };

    let group = tmp.groups.find((item) => item.id === +data[1]);

    let deviceIndex = group?.devices.findIndex((item) => item.id === +data[2]);

    let value = data[4].split(":");

    if(!deviceIndex){
      return;
    }

    let _t;

    switch (value[0]) {
      case "pressure_switch_a":
        
        if(group?.devices[deviceIndex].statuses["pressure_switch_a"] && group?.devices[deviceIndex].statuses["pressure_switch_a"] !== 'non_existent'){
         
          group.devices[deviceIndex].statuses["pressure_switch_a"] = value[1];
        }
        break;
      case "pressure_switch_b":
        if(group?.devices[deviceIndex].statuses["pressure_switch_b"] && group.devices[deviceIndex].statuses["pressure_switch_b"] !== 'non_existent'){
          group.devices[deviceIndex].statuses["pressure_switch_b"] = value[1];
        }
        break;
      case "pressure_switch_c":
        if(group?.devices[deviceIndex].statuses["pressure_switch_c"] && group.devices[deviceIndex].statuses["pressure_switch_c"] !== 'non_existent'){
          group.devices[deviceIndex].statuses["pressure_switch_c"] = value[1];
        }
        break;
    }
  } catch (error) {
    // alert(error);
    console.log(error, "updateFreezerStatus");
    console.log(freezers);
  }

  return freezers;
};