import { Layout } from "antd";

import React from "react";

import { Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks";
import Header from "../navigation/Header";

const { Content } = Layout;

const AuthLayout: React.FC = ({}) => {

  const auth: boolean = useAppSelector(state => !!state.userReducer.user?.token);

  return (
    <Layout className="containerWraperMinHeight">
      { auth && <Header authed={false} /> }
      <Content>
        <div className={!auth ? 'site-layout-background-auth' : ''}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default AuthLayout;
