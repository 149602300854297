import { message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";

interface IProps {
  childern: JSX.Element;
}

const PrivateRoute: React.FC<IProps> = ({ childern }) => {
  const auth: boolean = useAppSelector(
    (state) => !!state.userReducer.user?.token
  );

  if (!auth) {
    message.warning("Potrebna prijava.");
  }

  return auth ? childern : <Navigate replace to="/login" />;
};

export default PrivateRoute;
