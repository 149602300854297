import React from "react";

import { Form, message, Input, Button } from "antd";
import { changePasswordXHR } from "../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks";

interface IChangePasword {
  old_password: string;
  new_password: string;
  confirm: string;
}

interface IProps {
  close: () => void;
}

const ChangePasword: React.FC<IProps> = ({ close }) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const { changePasswordStatus } = useAppSelector((state) => state.userReducer);

  const onFinish = (values: IChangePasword) => {
    changePasswordXHR(
      {
        body: values,
        errorCallback: () => message.error("Dogodila se greška"),
        successCallback: () => {
          message.success("Uspješno ste promijenili lozinku.")
          close()
        },
      },
      dispatch
    );
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name={"old_password"}
        label={"Stara lozinka"}
        rules={[{ required: true, message: "Polje obavezno"}]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="new_password"
        label={"Nova lozinka"}
        rules={[
          {
            required: true,
            message:  "Polje obavezno",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={"Potvrdi"}
        dependencies={["new_password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message:  "Polje obavezno",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Lozinke se ne podudaraju")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          loading={changePasswordStatus === "loading"}
          type="primary"
          htmlType="submit"
          style={{ float: 'right' }}
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasword;
