import { accountsSlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import {
  IAccount,
  IAccountList,
  IFormAccount,
  IUserAccount,
} from "../../../models/user";
import api from "../../../services";
import { logoutXHR } from "../user/actionCreators";

export const accountsXHR = async (
  data: Partial<IDataToApi<undefined, IAccountList>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.accounts());
    const response = await api().get<IAccountList>(
      `accounts/accounts/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Token " + token },
      }
    );
    if (data.successCallback) {
      data.successCallback(response.data)
    }
    dispatch(accountsSlice.actions.accountsSucceess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.accountsFail(error.message));
  }
};

export const addUserXHR = async (
  data: Partial<IDataToApi<IFormAccount, IAccount>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.addUser());
    const response = await api().post<IAccount>(
      `accounts/registration/`,
      data.body,

      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.addUserSucceess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.addUserFail(error.message));
  }
};

export const getAccountXHR = async (
  data: Partial<IDataToApi<null, IUserAccount>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.getAccount());
    const response = await api().get<IUserAccount>(
      `accounts/accounts/${data.id}/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Token " + token },
      }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.getAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.getAccountFail(error.message));
  }
};

export const updateAccountXHR = async (
  data: Partial<IDataToApi<IFormAccount, IUserAccount>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(accountsSlice.actions.updateAccount());
    const response = await api().put<IUserAccount>(
      `accounts/accounts/${data.id}/`,
      data.body,
      { headers: { Authorization: "Token " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(accountsSlice.actions.updateAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(accountsSlice.actions.updateAccountFail(error.message));
  }
};

  

 

 

