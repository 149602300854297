
import Loading from "./components/Loading";
import { useAppDispatch, useAppSelector } from "./hooks";
import { meXHR, setMqttClient } from "./store/reducers/user/actionCreators";
import "./App.css";
import AppWraper from "./AppWraper";
import { useEffect, useState } from "react";
import mqtt from "mqtt";
import { getFreezersXHR } from "./store/reducers/all/actionCreators";
import { message } from "antd";
import { ConfigProvider } from 'antd';
import hrHR from 'antd/lib/locale/hr_HR';

function App() {


  const { meStatus } = useAppSelector((state) => state.userReducer);
  const { getFreezersStatus } = useAppSelector((state) => state.allReducer);

  const dispatch = useAppDispatch();

  const getFreezers = (updaterStatus: string) => {
    getFreezersXHR({
      loading: updaterStatus,
      successCallback: () => {
      },
      errorCallback: () => message.error("Dogodila se greska na dohvatu.")
    }, dispatch)
  }

  const getUser = async () => {
    let token = await localStorage.getItem("token");
    meXHR({
      successCallback: (data) => {
        getFreezers("loading")
      },
      queryParams: {token}
    }, dispatch);
  }

  useEffect(() => {
    getUser();
  }, [])

  if (meStatus === "loading" || getFreezersStatus === "loading") {
    return <Loading />
  }

  return <><div onClick={() => {
    let el = document.getElementsByTagName("body")[0];
    el.className = ""
  }} className="instructions" style={{ height: "100vh", top: 0, left: 0, width: "100%", background: "red" }} ><div></div></div> 
  <ConfigProvider locale={hrHR} > <AppWraper /></ConfigProvider>
   </>;
}

export default App;
