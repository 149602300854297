import { Button, DatePicker, message, Spin, Table, Typography } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITableColumn } from "../../models";
import { Alarm } from "../../models/all";
import {
  alarmsExportXHR,
  exportXHR,
  getAlarmsXHR,
  setAlarmDescriptionXHR,
} from "../../store/reducers/all/actionCreators";

const DATE_FORMAT = "DD-MM-YYYY HH:mm";

const { RangePicker } = DatePicker;

const DateFormat = "YYYY-MM-DD";

const Alarms: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    getAlarmsStatus,
    alarms,
    freezers,
    setAlarmDescriptionStatus,
    exportStatus,
  } = useAppSelector((state) => state.allReducer);
  const [dateParams, set_dateParams] = useState<{
    start: Moment | null;
    end: Moment | null;
  }>({
    start: moment().subtract(3, "d"),
    end: moment(),
  });

  const [freezer, set_freezer] = useState(
    freezers.find((x) => x.groups.map((x) => x.id).includes(id ? +id : 0))
  );
  console.log("🚀 ~ file: index.tsx ~ line 44 ~ freezer", freezer)

  const getAlarms = (
    queryParams: Record<string, string | number | null | undefined | boolean>
  ) => {
    getAlarmsXHR(
      {
        queryParams,
        errorCallback: () => message.error("Dogodila se greska."),
      },
      dispatch
    );
  };

  useEffect(() => {
    getAlarms({
      start: dateParams.start?.format("YYYY-MM-DD"),
      end: dateParams.end?.format("YYYY-MM-DD"),
      group_id: id ? +id : 0,
    });
  }, [dateParams]);

  const columns: ITableColumn<Alarm>[] = [
    {
      title: "Početak",
      dataIndex: "start",
      render: (text, value) => moment(text).format(DATE_FORMAT),
    },
    {
      title: "Završetak",
      dataIndex: "end",
      render: (text, value) => moment(text).format(DATE_FORMAT),
    },
    {
      title: "Opis",
      dataIndex: "description",
      render: (text, value) => (
        <Spin spinning={setAlarmDescriptionStatus === "loading" + id}>
          <UpdateReason text={value.description || ""} alarm_id={value.id} />
        </Spin>
      ),
    },
    {
      title: "Temperatura",
      dataIndex: "current_temp",
    },
    {
      title: "Razlog",
      dataIndex: "reason",
      render: text => text
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3}>{freezer?.name}</Typography.Title>
        <div
          style={{ padding: 12, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            loading={exportStatus === "loading"}
            onClick={() => {
              alarmsExportXHR(
                {
                  errorCallback: () => message.error("Dogodila se greska."),
                  body: {
                    start: dateParams.start?.format("YYYY-MM-DD") || "",
                    end: dateParams.end?.format("YYYY-MM-DD") || "",
                    group_id: id ? +id : 0,
                  },
                  successCallback: (data) => {
                    window.open(data.file, "_blank");
                  },
                },
                dispatch
              );
            }}
          >
            Izvezi
          </Button>
          <div style={{ width: 12 }} />
          <RangePicker
            value={[dateParams.start, dateParams.end]}
            onChange={(date) =>
              set_dateParams({
                start: date ? date[0] : null,
                end: date ? date[1] : null,
              })
            }
          />
        </div>
      </div>
      <Table
        loading={getAlarmsStatus === "loading"}
        rowKey={(item) => item.id}
        dataSource={alarms}
        columns={columns}
      />
    </>
  );
};

export default Alarms;

interface IProps {
  alarm_id: number;
  text: string;
}

const UpdateReason: React.FC<IProps> = ({ alarm_id, text }) => {
  const [description, set_description] = useState<string>(text);
  useEffect(() => {
    set_description(text);
  }, [text]);

  const dispatch = useAppDispatch();

  return (
    <div style={{ minWidth: 220, maxWidth: 420 }}>
      <Typography.Text
        editable={{
          onChange: (t) => {
            setAlarmDescriptionXHR(
              {
                errorCallback: () => message.error("Dogodila se greska"),
                id: alarm_id,
                body: { description: t },
              },
              dispatch
            );
          },
        }}
      >
        {description}
      </Typography.Text>
    </div>
  );
};
