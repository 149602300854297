import React, { useState } from "react";
import { Button, Divider, Form, Input, message, Typography, Row, Col, Modal } from "antd";
import { loginXHR, logoutXHR, resetPasswordEmailXHR } from "../../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import api from "../../services";
import { IApiResponse } from "../../models";

export interface IResetPassword {
  email: string
}

const Login: React.FC = () => {

  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [isModalVisible, set_isModalVisible] = useState<boolean>(false);
  const [resetPasswordMail, set_resetPasswordMail] = useState<string>('');
  const { loginStatus } = useAppSelector((state) => state.userReducer);

  // Methods
  const onFinish = (values: { email: string; password: string }) => {
    loginXHR(
      {
        body: values,
        errorCallback: (data: any, error: any) => {
          if (error && error.response?.status !== 400) {
            message.error("Dogodila se greska.");
            return;
          }
          message.warning("Neispravan unos");
        },
      },
      dispatch
    );
  };

  async function handleOk(){
    resetPasswordEmailXHR(
      {
        body: { email: resetPasswordMail },
        errorCallback: (error: any) => message.warning("Dogodila se greska."),
        successCallback: () =>  { 
          message.success("Link za resetiranje lozinke je uspješno poslan na vaš mail.")
          set_resetPasswordMail('')
          set_isModalVisible(false)
        }
      },
      dispatch
    );
  }

  function handleCancel(){
    set_isModalVisible(false)
    set_resetPasswordMail('')
  }

  function showModal(){
    set_isModalVisible(true)
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: '100%',
        minWidth: '100vw',
        height: '100%',
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <Row style={{ width: '100%', height: '100%', minHeight: '100vh' }}>
        <Col xs={0} lg={0}>
          <div 
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: "#001529"
            }}
          />
        </Col>
        <Col xs={24} lg={24}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: '100%',
            height: '100%',
            minHeight: '100vh'
          }}  
        >
          <div 
            style={{ 
              maxWidth: 600, width: 600,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title 
              level={1}
              style={{ height: 20, color: "#434343", fontWeight: 500, textAlign: 'center' }}
            >
              MCI komore
            </Typography.Title>

            <Typography.Title 
              level={5}
              style={{ paddingBottom: 24, color: "#434343aa", fontWeight: 300, textAlign: 'center' }}
            >
              Unesite podatke da biste pristupili aplikaciji
            </Typography.Title>
            <Form layout="vertical" onFinish={onFinish} autoComplete="off">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Ovo polje je obavezno." },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <div style={{ position: 'relative' }}>
              <Form.Item
                label={"Lozinka"}
                name="password"
                rules={[
                  { required: true, message: "Ovo polje je obavezno." },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
              </div>

              {/* Divider */}
              <Divider  />

              <Form.Item style={{ position: 'relative' }}>
                <Button
                  loading={loginStatus === "loading"}
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                >
                  {"Spremi"}
                </Button>
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  width: 480,
                }}
              />
            </Form>
          </div>
        </Col>
      </Row>


      {/* Forgot password modal */}
      <Modal 
        title={"Email za registraciju"}
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        okText={"Spremi"}
        cancelText={"Odustani"}
      >
        <Input 
          size="large"
          value={resetPasswordMail}
          onChange={e => set_resetPasswordMail(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Login;
